import NgayCanChi from "./common/NgayCanChi";
import { CON_GIAP, TEN_CAN, TEN_LUC_THU } from "./constants/constants";
import moment from "moment-mini";

export const getThanSat = (can, chi, conGiap) => {
  //can của ngày can chi, chi của ngày can chi, địa chi quái của quẻ kinh dịch
  let name = "";

  if (
    ((chi == CON_GIAP.THAN || chi == CON_GIAP.TYS || chi == CON_GIAP.THIN) &&
      conGiap == CON_GIAP.TYS) ||
    ((chi == CON_GIAP.DAN || chi == CON_GIAP.NGO || chi == CON_GIAP.TUAT) &&
      conGiap == CON_GIAP.NGO) ||
    ((chi == CON_GIAP.HOI || chi == CON_GIAP.MAO || chi == CON_GIAP.MUI) &&
      conGiap == CON_GIAP.MAO) ||
    ((chi == CON_GIAP.TYJ || chi == CON_GIAP.DAU || chi == CON_GIAP.SUU) &&
      conGiap == CON_GIAP.DAU)
  ) {
    name = "Tướng Tinh";
  } else if (
    ((chi == CON_GIAP.THAN || chi == CON_GIAP.TYS || chi == CON_GIAP.THIN) &&
      conGiap == CON_GIAP.DAN) ||
    ((chi == CON_GIAP.DAN || chi == CON_GIAP.NGO || chi == CON_GIAP.TUAT) &&
      conGiap == CON_GIAP.THAN) ||
    ((chi == CON_GIAP.HOI || chi == CON_GIAP.MAO || chi == CON_GIAP.MUI) &&
      conGiap == CON_GIAP.TYJ) ||
    ((chi == CON_GIAP.TYJ || chi == CON_GIAP.DAU || chi == CON_GIAP.SUU) &&
      conGiap == CON_GIAP.HOI)
  ) {
    name = "Dịch Mã";
  } else if (
    ((chi == CON_GIAP.THAN || chi == CON_GIAP.TYS || chi == CON_GIAP.THIN) &&
      conGiap == CON_GIAP.DAU) ||
    ((chi == CON_GIAP.DAN || chi == CON_GIAP.NGO || chi == CON_GIAP.TUAT) &&
      conGiap == CON_GIAP.MAO) ||
    ((chi == CON_GIAP.HOI || chi == CON_GIAP.MAO || chi == CON_GIAP.MUI) &&
      conGiap == CON_GIAP.TYS) ||
    ((chi == CON_GIAP.TYJ || chi == CON_GIAP.DAU || chi == CON_GIAP.SUU) &&
      conGiap == CON_GIAP.NGO)
  ) {
    name = "Đào Hoa";
  } else if (
    (chi == CON_GIAP.TYS && conGiap == CON_GIAP.HOI) ||
    (chi == CON_GIAP.SUU && conGiap == CON_GIAP.TYS) ||
    (chi == CON_GIAP.DAN && conGiap == CON_GIAP.SUU) ||
    (chi == CON_GIAP.MAO && conGiap == CON_GIAP.DAN) ||
    (chi == CON_GIAP.THIN && conGiap == CON_GIAP.MAO) ||
    (chi == CON_GIAP.TYJ && conGiap == CON_GIAP.THIN) ||
    (chi == CON_GIAP.NGO && conGiap == CON_GIAP.TYJ) ||
    (chi == CON_GIAP.MUI && conGiap == CON_GIAP.NGO) ||
    (chi == CON_GIAP.THAN && conGiap == CON_GIAP.MUI) ||
    (chi == CON_GIAP.DAU && conGiap == CON_GIAP.THAN) ||
    (chi == CON_GIAP.TUAT && conGiap == CON_GIAP.DAU) ||
    (chi == CON_GIAP.HOI && conGiap == CON_GIAP.TUAT)
  ) {
    name = "Bệnh Phù";
  } else if (
    (chi == CON_GIAP.TYS && conGiap == CON_GIAP.HOI) ||
    (chi == CON_GIAP.SUU && conGiap == CON_GIAP.TYS) ||
    (chi == CON_GIAP.DAN && conGiap == CON_GIAP.SUU) ||
    (chi == CON_GIAP.MAO && conGiap == CON_GIAP.DAN) ||
    (chi == CON_GIAP.THIN && conGiap == CON_GIAP.MAO) ||
    (chi == CON_GIAP.TYJ && conGiap == CON_GIAP.THIN) ||
    (chi == CON_GIAP.NGO && conGiap == CON_GIAP.TYJ) ||
    (chi == CON_GIAP.MUI && conGiap == CON_GIAP.NGO) ||
    (chi == CON_GIAP.THAN && conGiap == CON_GIAP.MUI) ||
    (chi == CON_GIAP.DAU && conGiap == CON_GIAP.THAN) ||
    (chi == CON_GIAP.TUAT && conGiap == CON_GIAP.DAU) ||
    (chi == CON_GIAP.HOI && conGiap == CON_GIAP.TUAT)
  ) {
    name = "Bệnh Phù";
  }

  if (
    (can == TEN_CAN.GIAP && conGiap == CON_GIAP.MAO) ||
    (can == TEN_CAN.AT && conGiap == CON_GIAP.THIN) ||
    (can == TEN_CAN.BINH && conGiap == CON_GIAP.NGO) ||
    (can == TEN_CAN.DINH && conGiap == CON_GIAP.MUI) ||
    (can == TEN_CAN.MAU && conGiap == CON_GIAP.NGO) ||
    (can == TEN_CAN.KY && conGiap == CON_GIAP.MUI) ||
    (can == TEN_CAN.CANH && conGiap == CON_GIAP.DAU) ||
    (can == TEN_CAN.TAN && conGiap == CON_GIAP.TUAT) ||
    (can == TEN_CAN.NHAM && conGiap == CON_GIAP.TYS) ||
    (can == TEN_CAN.QUY && conGiap == CON_GIAP.SUU)
  ) {
    name += `${name?.length > 0 ? " / Dương Nhẫn" : "Dương Nhẫn"}`;
  } else if (
    ((can == TEN_CAN.GIAP || can == TEN_CAN.MAU || can == TEN_CAN.CANH) &&
      (conGiap == CON_GIAP.SUU || conGiap == CON_GIAP.MUI)) ||
    ((can == TEN_CAN.AT || can == TEN_CAN.KY) &&
      (conGiap == CON_GIAP.TYS || conGiap == CON_GIAP.THAN)) ||
    ((can == TEN_CAN.BINH || can == TEN_CAN.DINH) &&
      (conGiap == CON_GIAP.HOI || conGiap == CON_GIAP.DAU)) ||
    ((can == TEN_CAN.NHAM || can == TEN_CAN.QUY) &&
      (conGiap == CON_GIAP.MAO || conGiap == CON_GIAP.TYJ)) ||
    (can == TEN_CAN.TAN && (conGiap == CON_GIAP.NGO || conGiap == CON_GIAP.DAN))
  ) {
    name += `${name?.length > 0 ? " / Quý Nhân" : "Quý Nhân"}`;
  }
  return name;
};

export const getLucThu = (can) => {
  //can của ngày can chi
  if (can == TEN_CAN.GIAP || can == TEN_CAN.AT) {
    return getListLucThu(0);
  } else if (can == TEN_CAN.BINH || can == TEN_CAN.DINH) {
    return getListLucThu(1);
  } else if (can == TEN_CAN.MAU) {
    return getListLucThu(2);
  } else if (can == TEN_CAN.KY) {
    return getListLucThu(3);
  } else if (can == TEN_CAN.CANH || can == TEN_CAN.TAN) {
    return getListLucThu(4);
  } else if (can == TEN_CAN.NHAM || can == TEN_CAN.QUY) {
    return getListLucThu(5);
  }
  return [];
};

export const getListLucThu = (index) => {
  let temp = [];

  for (let i = index; i < TEN_LUC_THU.length; i++) {
    temp.push(TEN_LUC_THU[i]);
  }
  if (index != 0) {
    for (let i = 0; i < index; i++) {
      temp.push(TEN_LUC_THU[i]);
    }
  }

  return temp;
};

export const getCanChiOfDay = () => {
  let timeDefault = moment("1925-01-01").valueOf();
  let now = moment();
  let timeNow = moment(
    `${now.year()}-${now.month() + 1}-${now.date()} 00:00:00`,
    "YYYY-MM-DD hh:mm:ss"
  ).valueOf();
  let result = Math.ceil(
    ((timeNow - timeDefault) / (1000 * 60 * 60 * 24)) % 60
  );

  let position = (result + 22) % 60;

  if (position == 0) {
    return NgayCanChi[59] || "";
  } else {
    return NgayCanChi[position - 1] || "";
  }
};
