import React, { useEffect, useState } from "react";
import NgayCanChi from "../common/NgayCanChi";
import ThangCanChi from "../common/ThangCanChi";
import _ from "lodash";
import ListQueKinhDich from "../common/QueKinhDich";
import ListKhongVong from "../common/KhongVong";
import ListNguyetPha from "../common/NguyetPha";
import { getCanChiOfDay } from "../utils";

const LapQueDich = (props) => {
  const canChiOfDay = getCanChiOfDay();
  const thangNo = ThangCanChi.find(
    (item) => item.nameCanChi == canChiOfDay?.chi
  ).no;
  const [errName, setErrName] = useState(true);
  const [errQuestion, setErrQuestion] = useState(true);
  const [isLapQuedich, setIsLapQueDich] = useState(true);
  const [khongVong, setKhongVong] = useState("");
  const [nguyetPha, setNguyetPha] = useState("");
  const [ngayCanChi, setNgayCanChi] = useState(1);

  useEffect(() => {
    getKhongVong(canChiOfDay?.no);
    getNguyetPha(thangNo);
    props.getNgayCanChi(canChiOfDay);
  }, [canChiOfDay, thangNo]);

  useEffect(() => {
    props.getKhongVong(khongVong);
    props.getNguyetPha(nguyetPha);
  }, [khongVong, nguyetPha]);

  function randomInteger(a, b) {
    return Math.floor(Math.random() * (b - a + 1)) + a;
  }

  const onClickLapQueDich = () => {
    let codeQuaiArr = []; // mảng CodeQuai real
    let codeQuaiHaoDongArr = []; // mảng CodeQuai hào động
    let isHaoDong = false; // có hào động

    // chạy 6 lần để ra quẻ dịch
    for (let i = 1; i <= 6; i++) {
      let giun = 0; // mặt con giun trong đồng xu trên app (ko hiển thị trên web)
      let tau = 0; // mặt chữ tàu trong đồng xu trên app (ko hiển thị trên web)

      // mỗi lần chạy gọi 3 lần random (đại diện cho việc tung 3 đồng xu)
      for (let e = 1; e <= 3; e++) {
        // random ra số  1 hoặc sô 2 (đại diện cho mặt con giun hay mặt chữ tàu trên đồng xu)
        let random = randomInteger(1, 3);
        if (random === 1) {
          // nếu random ra số 1 tương đương mặt chữ tàu
          tau++;
        } else {
          // ngược lại ra số 2 thì tương đương mặt con giun
          giun++;
        }
      }

      if (giun == 3) {
        // 3 đồng xu đều có mặt con giun
        // status 1 : CodeQuai = Lien, CodeQuaiHaoDong ngược lại với CodeQuai
        codeQuaiArr.push("Lien");
        codeQuaiHaoDongArr.push("Dut");
        isHaoDong = true; // quẻ kinh dịch sẽ có hào động khi 6 lần tung đồng xu có chứa ít nhất 1 lần ở trạng thái 1 hoặc 4
      } else if (giun == 2) {
        // 3 đồng xu có 2 đồng xu mặt con giun
        // status 2 : CodeQuai = Dut, CodeQuaiHaoDong giống CodeQuai
        codeQuaiArr.push("Dut");
        codeQuaiHaoDongArr.push("Dut");
      } else if (tau == 2) {
        // 3 đồng xu có 2 đồng xu mặt chữ tàu
        // status 3 : CodeQuai = Lien, CodeQuaiHaoDong giống CodeQuai
        codeQuaiArr.push("Lien");
        codeQuaiHaoDongArr.push("Lien");
      } else {
        // các trường hợp còn lại
        // status 4 : CodeQuai = Dut, CodeQuaiHaoDong ngược lại với CodeQuai
        codeQuaiArr.push("Dut");
        codeQuaiHaoDongArr.push("Lien");
        isHaoDong = true; // quẻ kinh dịch sẽ có hào động khi 6 lần tung đồng xu có chứa ít nhất 1 lần ở trạng thái 1 hoặc 4
      }
    }

    if (codeQuaiArr?.length == 6) {
      let CodeQuai = codeQuaiArr.join("-");
      let obj = _.find(ListQueKinhDich, ["CodeQuai", CodeQuai]) || {};
      props.getQueKinhDich(obj);

      props.getNgayCanChi(ngayCanChi);
      setIsLapQueDich(true);
    }

    if (codeQuaiHaoDongArr?.length == 6) {
      let CodeQuaiHaoDong = codeQuaiHaoDongArr.join("-");
      let objHaoDong =
        _.find(ListQueKinhDich, ["CodeQuai", CodeQuaiHaoDong]) || {};
      // objHaoDong.CodeQuaiGocPrototype = codeQuaiArr.join("-");
      props.getQueHaoDong(isHaoDong ? objHaoDong : {}); // quẻ có hào động mới lấy kết quả hào động
      isHaoDong = false;
    }
  };

  const onChangeInfo = (val, setStateKey) => {
    if (val?.length > 0) {
      setStateKey(false);
    } else {
      setStateKey(true);
    }
  };

  const getKhongVong = (no) => {
    let obj = _.find(NgayCanChi, ["no", no * 1]) || {};

    if (obj?.canChi?.length > 0) {
      ListKhongVong.map((item) => {
        let khong_vong = _.indexOf(item?.listCanChi, obj?.canChi);

        if (khong_vong != -1) return setKhongVong(item?.listConGiap);
      });
    }

    // setIsLapQueDich(false);
    setNgayCanChi(obj);
  };

  const getNguyetPha = (no) => {
    let obj = _.find(ThangCanChi, ["no", no * 1]) || {};

    if (obj?.nameCanChi?.length > 0) {
      let nguyet_pha = _.findIndex(ListNguyetPha, [
        "thangCanChi",
        obj?.nameCanChi,
      ]);

      if (nguyet_pha != -1) setNguyetPha(ListNguyetPha[nguyet_pha]?.nguyetPha);
    }

    // setIsLapQueDich(false);
  };

  const renderCanChi = (data) => {
    return data.map((item) => {
      return (
        <option key={item?.no} value={item?.no}>
          {item?.nameCanChi}
        </option>
      );
    });
  };

  return (
    <div className="w-100 div-lap-que-dich">
      <p className="header-text-orange text-uppercase text-center">
        PHẦN MỀM LẬP QUẺ KINH DỊCH
      </p>
      <div className="div-info">
        <div className={`row mb-4`}>
          <div className="col-3">
            <strong>Họ tên:</strong>
          </div>
          <div className="col-9 text-left">
            <input
              placeholder="Họ tên"
              className="pl-2 w-100"
              onChange={(e) => onChangeInfo(e?.target?.value, setErrName)}
            />
            {/* {errName && <span style={{ color: "red" }}>Vui lòng nhập họ tên</span>} */}
          </div>
        </div>
        <div className={`row mb-4`}>
          <div className="col-3">
            <strong>Câu hỏi:</strong>
          </div>
          <div className="col-9 text-left">
            <input
              placeholder="Câu hỏi"
              className="pl-2 w-100"
              onChange={(e) => onChangeInfo(e?.target?.value, setErrQuestion)}
            />
            {/* {errQuestion && <span style={{ color: "red" }}>Vui lòng nhập câu hỏi</span>} */}
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-3 text-center">
            <p className="font-weight-bold mb-1">Ngày</p>
            <select
              className="w-100"
              style={{ height: "28px" }}
              placeholder="Ngày"
              onChange={(e) => {
                getKhongVong(e?.target?.value);
                setIsLapQueDich(false);
              }}
              defaultValue={canChiOfDay?.no}
            >
              {renderCanChi(NgayCanChi)}
            </select>
          </div>
          <div className="col-3 text-center">
            <p className="font-weight-bold mb-1">Tháng</p>
            <select
              className="w-100"
              style={{ height: "28px" }}
              placeholder="Tháng"
              onChange={(e) => {
                getNguyetPha(e?.target?.value);
                setIsLapQueDich(false);
              }}
              defaultValue={thangNo}
            >
              {renderCanChi(ThangCanChi)}
            </select>
          </div>
          <div className="col-3 text-center">
            <p className="font-weight-bold mb-1">KV (Không Vong)</p>
            <input
              placeholder="KV (Không Vong)"
              disabled
              className="w-100 text-center"
              value={isLapQuedich ? khongVong : undefined}
            />
          </div>
          <div className="col-3 text-center">
            <p className="font-weight-bold mb-1">NP (Nguyệt Phá)</p>
            <input
              placeholder="NP (Nguyệt Phá)"
              disabled
              className="w-100 text-center"
              value={isLapQuedich ? nguyetPha : undefined}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn-blue"
            onClick={onClickLapQueDich}
            style={{ cursor: `pointer` }}
          >
            Lập Quẻ Dịch
          </button>
        </div>
      </div>
    </div>
  );
};

export default LapQueDich;
